
import ReactPlayer from 'react-player';
import { Context } from "../../utils/store";
import DateAndTopicArticleAlone from "./DateAndTopicArticleAlone";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import MostViewed from "../MostViewed";
import Network from "../../constants/Network";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { _isArticleArchived } from "../../utils/isArchived";
import extractVars from "../../utils/extractVariablesFromTemplates";
import getPubs from '../../utils/pubs/getPubs'
import ButtonArticle from '../../components/Articles/ButtonArticle';
import { Carousel } from "react-responsive-carousel";

const initializeCastApi = function () {
  cast.framework.CastContext.getInstance().setOptions({
    receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
    autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
  });
};


class Article extends React.Component {
  static contextType = Context;

  remotePlayerController = null;

  state = {
    isArchived: false,
    content: "",
    pubPlaying: false,
    castSession: null,
    castState: null,
    isCastPlaying: false,
    castStarted: false,
    playing: false,
    muted: false,
    volume: 1,
    videoReady: false
  };

  initScripts = async () => {
    const script = document.createElement("script");

    script.src = "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";
    script.type = "text/javascript";

    document.body.appendChild(script);
  }

  initCast = (media) => {
    let template_variables = extractVars(media.template_variables, "article");
    let {
      video_date_timestamp,
      video_thumbs,
      video_fichier,
      video_archivee,
    } = template_variables;

    let castSession = cast.framework.CastContext.getInstance();
    let castState = cast.framework.CastContext.getInstance().getCastState();

    let url = this.state.pubPlaying ?
      this.state.pub.fichier
      : (video_archivee === 1 ? Network.ARCHIVE_URL : Network.BASE_URL) + video_fichier

    //this.handleCast(castSession, url)

  }

  handleCast = ($castSession, $url) => {
    let castSession = $castSession.getCurrentSession();
    let mediaInfo = new chrome.cast.media.MediaInfo($url, 'video/mp4');
    let request = new chrome.cast.media.LoadRequest(mediaInfo);
    castSession.loadMedia(request).then(
      () => {
        console.log('Load succeed');
        this.setState({ castStarted: true });
      },
      function (errorCode) {
        console.log('Error code: ' + errorCode);
      });
  }

  _handlePlayPause = () => {
    this.props.media.playPause();
  };

  startVideo = () => {
    if (this.state.pubPlaying) {
      this.setState({
        pubPlaying: false,
        playing: true
      })
    }
  };

  initPub = async () => {
    let pub = await getPubs('preroll', false, true, true);
    let userAgent = window.navigator.userAgent.toLowerCase();

    this.setState({
      pub: pub,
      pubPlaying: pub?.fichier?.length > 0,
      videoReady: true,
      isSafari: /safari/.test( userAgent ) || /iphone|ipod|ipad/.test( userAgent )
    });
  };
  
  componentDidMount() {

    this.initPub();
    this.initScripts();

    window['__onGCastApiAvailable'] = (isAvailable) => {
      if (isAvailable) {
        initializeCastApi();
        this.remotePlayerController = new cast.framework.RemotePlayerController(new cast.framework.RemotePlayer());
        this.registerListener();
      }
    };
  }

  /**
   * Register Listener for Google ChromeCast Event
   */
  registerListener = () => {
    let castSession = cast.framework.CastContext.getInstance();

    castSession.addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, (event) => {
      let castState = event.castState;


      switch (event.castState) {
        case 'CONNECTED':
          this.setState({
            castState: event.castState
          })

        case 'NOT CONNECTED':
          this.setState({
            castState: event.castState
          })
      }
    });

    this.remotePlayerController.addEventListener(
      cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      () => {
        this.setState({
          isCastPlaying: !this.state.isCastPlaying
        });
      });
  }
  
  /**
   * Player for Ads before content
   * @returns {JSX.Element}
   */
  pubPlayer() {
    return (
      <div className="media article-alone-media-controls">
        <div className="player-wrapper">
          <ReactPlayer
            url={
              this.state.isSafari 
                ? this.state.pub.fichier.replace('.webm','.mp4') 
                : this.state.pub.fichier
            }
            muted={this.state.muted}
            volume={this.state.volume}
            playsinline={true}
            controls={false}
            autoPlay={true}
            playing={this.state.playing}
            className="react-player"
            onPlay={() => this.setState({ playing: true })}
            onPause={() => this.setState({ playing: false })}
            width='100%'
            height='100%'
            pip={false}
            config={{ file: { 
              attributes: {
                controlsList: 'nodownload'
              }
            }}}
            onEnded={() => this.setState({ pubPlaying: false })}
            onError={error => {
              console.info('Error did occur on error: ', error, this.state.playing);
              this.setState({
                playing: false,
                muted: true,
                volume: 0
              }, () => {
                this.setState({ playing: true });
              });
            }}
            onReady={(player) => {
              if (typeof window !== 'undefined')  {
                var video = document.querySelector('video');
                var promise = video.play();

                if (promise !== undefined) {
                  promise.then(_ => {
                    console.info('OK', this.state.playing);
                    this.setState({
                      playing: true
                    });
                  }).catch(error => {
                    console.info('Error did occur on catch: ', error, this.state.playing);
                    this.setState({
                      playing: false,
                      muted: true,
                      volume: 0
                    }, () => {
                      this.setState({ playing: true });
                    });
                  });
                }
              }
            }}
          />
        </div>

        <div className="article-alone-media-controls-container">
          {this.state.muted ? (
            <VolumeOffIcon
              style={{
                position: 'relative',
                bottom: 10,
                right: 10,
                fontSize: 50
              }}
              onClick={() => {
                this.setState({ muted: false, volume: 1 });
              }}
            />
          ) : (
              <VolumeUpIcon
                style={{
                  position: 'relative',
                  bottom: 10,
                  right: 10,
                  fontSize: 50
                }}
                onClick={() => {
                  this.setState({ muted: true, volume: 0 });
                }}
              />
            )}
        </div>
      </div>
   
    );
  }

  /**
   * Web video Player
   * @param template_variables
   * @param data
   * @returns {JSX.Element}
   */
  videoPlayer(template_variables, data) {
    let {
      video_date_timestamp,
      video_thumbs,
      video_fichier,
      video_archivee,
    } = template_variables;
    let { type, parentName, pagetitle, content } = data;

    return (
      <div className="media article-alone-media-controls">
        {!this.state.playing && this.state.castState !== 'CONNECTED' && this.state.isSafari ?
          <ButtonArticle
            typearticle="big"
            onClick={() => {
              this.setState({ playing: true });
            }}
          />
          : null}

        <div className="player-wrapper">
          {
            this.state.videoReady && (
              <ReactPlayer
                url={this.state.pubPlaying
                  ? this.state.isSafari 
                    ? this.state.pub.fichier.replace('.webm','.mp4') 
                    : this.state.pub.fichier
                  : (video_archivee == 1 ? Network.ARCHIVE_URL : Network.BASE_URL) + (this.state.isSafari ? video_fichier.replace('.webm','.mp4') : video_fichier)
                }
                muted={this.state.muted}
                volume={this.state.volume}
                playsinline={true}
                controls={!this.state.pubPlaying}
                autoPlay={true}
                playing={this.state.playing}
                className="react-player"
                onPlay={() => this.setState({ playing: true })}
                onPause={() => this.setState({ playing: false })}
                width='100%'
                height='100%'
                pip={false}
                config={{ file: { 
                  attributes: {
                    controlsList: 'nodownload'
                  }
                }}}
                onEnded={this.startVideo}
                onError={error => {
                  console.info('Error did occur on error: ', error, this.state.playing);
                  this.setState({
                    playing: false,
                    muted: true,
                    volume: 0
                  }, () => {
                    this.setState({ playing: true });
                  });
                }}
                onReady={(player) => {
                  if (typeof window !== 'undefined')  {
                    var video = document.querySelector('video');
                    var promise = video.play();
    
                    if (promise !== undefined) {
                      promise.then(_ => {
                        console.info('OK', this.state.playing);
                        this.setState({
                          playing: true
                        });
                      }).catch(error => {
                        console.info('Error did occur on catch: ', error, this.state.playing);
                        this.setState({
                          playing: false,
                          muted: true,
                          volume: 0
                        }, () => {
                          this.setState({ playing: true });
                        });
                      });
                    }
                  }
                }}
              />
            )
          }
        </div>

        {this.state.pubPlaying ?
          <div className="article-alone-media-controls-container">
            {this.state.muted ? (
              <VolumeOffIcon
                style={{
                  position: 'relative',
                  bottom: 10,
                  right: 10,
                  fontSize: 50
                }}
                onClick={() => {
                  this.setState({ muted: false, volume: 1 });
                }}
              />
            ) : (
                <VolumeUpIcon
                  style={{
                    position: 'relative',
                    bottom: 10,
                    right: 10,
                    fontSize: 50
                  }}
                  onClick={() => {
                    this.setState({ muted: true, volume: 0 });
                  }}
                />
              )}
          </div>
          :
          this.state.castState == 'CONNECTED' ?
            <div className="py-16 bg-primary-white bg-opacity-70 bottom-0 absolute w-full px-20">
              <PauseIcon
                onClick={() => {
                  mediaProps.pause();
                }}
                className="text-dark-blue transition-all duration-200 hover:text-primary-blue"
              />
            </div>
            : null
        }
      </div>
    );
  }

  /**
   * Cast player (Control the cast Device)
   * @param template_variables
   * @param data
   * @returns {JSX.Element}
   */
  castPlayer(template_variables, data) {
    let {
      video_date_timestamp,
      video_thumbs,
      video_fichier,
      video_archivee,
    } = template_variables;
    let { type, parentName, pagetitle, content } = data;

    return (
      <div className="article-cast-player">
        <img
          className="article-alone-image"
          src={`${Network.BASE_URL}${video_thumbs}`}
          alt="Cast en cours"
        />
        <div className="article-cast-start" onClick={() => this.state.castStarted ? null : this.startCast(video_archivee, video_fichier)}>
          {this.state.castStarted ? <p>Lecure en cours sur le Chromecast</p> :
            <PlayArrowIcon
              className="play-cast text-dark-blue transition-all duration-200 hover:text-primary-blue"
            />}
        </div>
        <div className="py-16 bg-primary-white bg-opacity-70 bottom-0 absolute w-full px-20">
          {this.state.isCastPlaying ? <PlayArrowIcon
            onClick={() => {
              this.castPlayPause();
            }}
            className="text-dark-blue transition-all duration-200 hover:text-primary-blue"
          /> :
            <PauseIcon
              onClick={() => {
                this.castPlayPause();
              }}
              className="text-dark-blue transition-all duration-200 hover:text-primary-blue"
            />}

        </div>
      </div>
    );
  }

  /**
   * Set PlayOrPause on cast device
   */
  castPlayPause() {
    this.remotePlayerController.playOrPause();
  }
  
  componentDidMount(){
    this.initPub();
    this.initScripts();

    window['__onGCastApiAvailable'] = (isAvailable) => {
      if (isAvailable) {
        initializeCastApi();
        this.remotePlayerController = new cast.framework.RemotePlayerController(new cast.framework.RemotePlayer());
        this.registerListener();
      }
    };
    window.twttr = (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
    
      t._e = [];
      t.ready = function(f) {
        t._e.push(f);
        twttr.widgets.load()
      };
    
      return t;
    }(document, "script", "twitter-wjs"));
  }
  startCast(video_archivee, video_fichier) {
    let castSession = cast.framework.CastContext.getInstance();
    let castState = cast.framework.CastContext.getInstance().getCastState();

    let url = (video_archivee === 1 ? Network.ARCHIVE_URL : Network.BASE_URL) + video_fichier

    this.handleCast(castSession, url)
  }

  render() {
    let { data } = this.props;
    data = data[0];
    let template_variables = extractVars(data.template_variables, "article");
    let {
      video_date_timestamp,
      video_thumbs,
      video_fichier,
      video_archivee,
    } = template_variables;
    let { type, parentName, pagetitle, content,images_express } = data;
    return (
      <div className="article-alone-container">
        <h1>{pagetitle}</h1>
        {/* <h1>muted {this.state.muted ? 'true' : 'false'}</h1>
        <h1>playing {this.state.playing ? 'true' : 'false'}</h1>
        <h1>pubPlaying {this.state.pubPlaying ? 'true' : 'false'}</h1> */}
        <div className="article-alone-media-container">
          {type === "info_express" ? (
            <>
              {this.state.pubPlaying ?
                this.pubPlayer()
                : 
                images_express?.length <=1  ? 
                  images_express.map((image,index) =>
                    <img
                      className="article-alone-image mt-10"
                      src={`${Network.BASE_URL}${image}`}
                      alt={`Image ${pagetitle} ${index}`}
                      key={`Image ${index}`}
                    /> 
                  )
                :
                  <Carousel>
                      {images_express.map((image,index)=>
                          <div key={`Image ${index}`}>
                            <img
                              className="article-alone-image mt-10"
                              src={`${Network.BASE_URL}${image}`}
                              alt={`Image ${pagetitle} ${index}`}
                            />
                          </div>
                      )}                  
                  </Carousel>
              }
            </>
          ) : (
              this.state.castState == 'CONNECTED' ?
                this.castPlayer(template_variables, data) :
                this.videoPlayer(template_variables, data)
            )}
          <DateAndTopicArticleAlone
            date={video_date_timestamp}
            topic={parentName}
            data={data}
            pubPlaying={this.state.pubPlaying}
            initCast={this.initCast}
          />
        </div>
        <div
          key={"article_content_container"}
          dangerouslySetInnerHTML={{ __html: content }}
          className="article-alone-content"
        />
        <MostViewed />
      </div>

    );
  }
}

export default Article;
