import { useEffect, useState } from "react";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";

export default function ButtonArticle( {typearticle="",onClick=()=>{}}) {

  const [isPhone, setIsPhone] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", resize);
    function resize() {
      window.innerWidth > 319 && window.innerWidth < 601 ? setIsPhone(true) : setIsPhone(false)
      window.innerWidth > 600 && window.innerWidth < 1024 ? setIsTablet(true) : setIsTablet(false)
    } 
    resize();
    
    return () => window.removeEventListener("resize", resize)
    }, []);
    typearticle = typearticle === "" ? "-base" : `-${typearticle}`
    return (
         <div className={`default-container-button-article default-container-button-article${typearticle}`} onClick={onClick}>
        <PlayArrowIcon fontSize={isPhone ? "small" : "default"} className="play-button-article"/>
      </div>
    )
}
