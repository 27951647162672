import ButtonArticle from "./ButtonArticle";
import DateAndTopic from "./DateAndTopic";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import LinkArticle from "./LinkArticle";
import Network from "../../constants/Network";
import extractVars from "../../utils/extractVariablesFromTemplates";
import Image from 'next/image'
export default function BigArticle({ data, isAloneArticle }) {
  let template_variables = extractVars(data.template_variables, "article");
  let { video_date_timestamp, video_thumbs } = template_variables;
  let { type, parentName, pagetitle, content } = data;
  return (
    <LinkArticle data={data}>
      <div className="big-a-container">
        <div className="big-a-sub-container">
          <Image
            className="big-a-image"
            src={`${Network.BASE_URL}${video_thumbs}`}
            alt={`Illustration de l'article`}
            layout="responsive"
            width={681}
            height={369}
          />
          {type === "video" && <ButtonArticle typearticle={"big"} />}

          <div className="big-a-bottom-block">
            <div className="big-a-sub-bottom-block">
              <p className="big-a-parent-name">{parentName}</p>
              <p className="big-a-pagetitle"> {pagetitle}</p>
              <DateAndTopic
                date={video_date_timestamp}
                topic={parentName}
                typearticle={"big"}
              />
            </div>
          </div>
          
        </div>

      </div>
    </LinkArticle>
  );
}
