import ButtonArticle from "./ButtonArticle";
import DateAndTopic from "./DateAndTopic";
import LinkArticle from "./LinkArticle";
import Network from "../../constants/Network";
import extractVars from "../../utils/extractVariablesFromTemplates";
import { useEffect } from "react";

export default function LongArticle({ data }) {
  let template_variables = extractVars(data.template_variables, "article");
  let {
    video_date_timestamp,
    video_thumbs,
  } = template_variables;
  let { type, parentName, pagetitle } = data;

  return (
    <LinkArticle data={data}>
    <div className="ba-home-container">
      <div className="ba-home-container-image">
        <img
          className="ba-home-image"
          src={`${Network.BASE_URL}${video_thumbs}`}
          alt="Image de l'article"
        />
        {type === "video" && <ButtonArticle typearticle={"big"} />}
      </div>
      <div className="ba-home-container-content">
      <p className="article-long-title ba-home-title"> {pagetitle}</p>
      <DateAndTopic date={video_date_timestamp} topic={parentName} />
      </div>
    </div>
    </LinkArticle>
  );
}
